import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import * as Yup from 'yup';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { FdrForgotPasswordFormWrapper } from './fdr-b2b-forgot-password.styles';

export interface IFdrForgotPasswordFormState {
  username: string;
}

interface IFdrForgotPasswordFormProps {
  usernameLabel?: string;
  onSubmit: (
    values: IFdrForgotPasswordFormState,
    helpers: FormikHelpers<IFdrForgotPasswordFormState>
  ) => Promise<void>;
  validationSchema: Yup.ObjectSchema<IFdrForgotPasswordFormState>;
}

export function FdrForgotPasswordForm({
  usernameLabel,
  validationSchema,
  onSubmit,
}: IFdrForgotPasswordFormProps): ReactElement {
  const [t] = useTranslation(['forms', 'auth']);

  return (
    <Formik<IFdrForgotPasswordFormState>
      validationSchema={validationSchema}
      initialValues={{ username: '' }}
      onSubmit={onSubmit}
    >
      {formik => {
        return (
          <FdrForgotPasswordFormWrapper
            noValidate
            onSubmit={(e: unknown) =>
              formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
            }
          >
            <div className="form-text">
              <ValidatedTextInput
                required
                name="username"
                label={usernameLabel}
                showErrorMessage
              />
            </div>
            <BsButton
              disabled={formik.isSubmitting}
              type="submit"
              className="form-submit-button"
            >
              {t('forms:submit.button')}
            </BsButton>
          </FdrForgotPasswordFormWrapper>
        );
      }}
    </Formik>
  );
}
