import styled from 'styled-components';
import { sx2CssThemeFn, SxStyleProp } from '@hotelplan/util.theme.sxc';
import { FdrRegistrationRoute } from 'components/candidate/fdr-auth-routes';

const layoutBlockStyles: SxStyleProp = {
  width: ['100%', '50%'],
  py: [4, '50px'],
  px: [4, '62px'],
};

export const FdrLayoutWrapper = styled.div(
  sx2CssThemeFn({
    bg: 'bgLightBlue',
    pt: [0, '45px'],
    pb: [0, '75px'],
  })
);

export const FdrLayoutBlockWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  ...theme.auth.loginLayoutBlockStyles,
}));

export const FdrLayoutBlock = styled.div(({ theme }) =>
  sx2CssThemeFn(layoutBlockStyles, {
    borderRight: [null, '1px solid'],
    borderColor: [null, 'borderColor'],
    '.form-title': theme.auth.formTitleStyles,
  })
);

export const FdrRegistrationBlock = styled.div(({ theme }) =>
  sx2CssThemeFn(layoutBlockStyles, {
    '.form-title': theme.auth.formTitleStyles,
  })
);

export const FdrLoginSocialTop = styled.div(
  ({ theme }) => theme.auth.loginSocialTop
);

export const FdrB2CLoginFormWrapper = styled.form(
  ({ theme }) => theme.auth.loginForm
);

export const FdrFailureLoginErrorMessage = styled.p(
  ({ theme }) => theme.auth.errorMessage
);

export const FdrB2CLoginLinkWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  ...theme.auth.b2cLoginLinkWrapper,
}));

export const FdrRegisterBaseLink = styled(FdrRegistrationRoute)(
  ({ theme }) => theme.buttons.primary,
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    cursor: 'pointer',
    ':hover': {
      color: 'white',
    },
  }
);

export const FdrRegistrationRouterLink = styled(FdrRegistrationRoute)(
  ({ theme }) => theme.buttons.linkBtn,
  sx2CssThemeFn({
    fontSize: 3,
    fontWeight: 600,
  })
);
