import { useTranslation } from 'next-i18next';
import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { AuthChannelType } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { FdrAuthFormOverlay } from 'components/candidate/fdr-auth-form-overlay';
import { FdrB2bLoginForm } from 'components/candidate/fdr-auth-forms';
import { FdrB2bMarkupForm } from 'components/candidate/fdr-b2b-markup-form';
import {
  FdrB2BAuthPanelContent,
  FdrB2BLoginFormOverlay,
  FdrLogoutIconHolder,
} from './fdr-auth-panel.styles';

export function FdrAuthPanelDesktop(): ReactElement {
  const [t] = useTranslation('auth');
  const {
    user: { channel },
    client,
  } = useAuthContext();

  // TODO: Not clear at what case this will be true (useB2BAuthOverlay). Need to investigate.
  const [showAuthB2BOverlay, , hide] = useToggleState(false);

  async function logoutHandler() {
    if (typeof window !== 'undefined') {
      await client.logout();
      window.location.reload();
    }
  }

  if (showAuthB2BOverlay)
    return (
      <FdrB2BLoginFormOverlay
        show={showAuthB2BOverlay}
        name="fdrB2BLoginFormOverlay"
        onClose={hide}
      >
        <FdrAuthFormOverlay title={t('auth:b2b.overlay.title')}>
          <FdrB2bLoginForm onLogin={hide} hasForgotPasswordLink={false} />
        </FdrAuthFormOverlay>
      </FdrB2BLoginFormOverlay>
    );

  if (channel !== AuthChannelType.B2B) return null;

  return (
    <FdrB2BAuthPanelContent data-id="fdr-b2b-auth-desktop">
      <BsButtonWithIcon
        onClick={logoutHandler}
        variant="headerBtn"
        icon={{ name: 'logout-b2b-v2' }}
      >
        <span className="contact-text">{t('auth:b2b.logout')}</span>
      </BsButtonWithIcon>
      <FdrB2bMarkupForm />
    </FdrB2BAuthPanelContent>
  );
}

export function FdrAuthPanelMobile(): ReactElement {
  const [t] = useTranslation('auth');
  const { client } = useAuthContext();

  async function logoutHandler() {
    if (typeof window !== 'undefined') {
      await client.logout();
      window.location.reload();
    }
  }

  return (
    <FdrB2BAuthPanelContent
      data-id="fdr-b2b-auth-mobile"
      className="logoutSiteTool"
      onClick={logoutHandler}
    >
      <FdrLogoutIconHolder>
        <BsIcon name="logout-b2b-v2" />
      </FdrLogoutIconHolder>
      <span className="icon-label">{t('auth:b2b.logout')}</span>
    </FdrB2BAuthPanelContent>
  );
}
