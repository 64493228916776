import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrFailureLoginErrorMessage = styled.form(
  ({ theme }) => theme.auth.errorMessage
);

export const FdrLoginForm = styled.form(({ theme }) => theme.auth.loginForm);

export const FdrLayoutWrapper = styled.div(
  ({ theme }) => theme.auth.b2bLoginLayout
);

export const FdrLayoutBlockWrapper = styled.div(({ theme }) =>
  sx2CssThemeFn({
    display: 'flex',
    maxWidth: [null, '485px'],
    ...theme.auth.loginLayoutBlockStyles,
  })
);

export const FdrLayoutBlock = styled.div(({ theme }) =>
  sx2CssThemeFn({
    py: [4, '50px'],
    px: [4, '62px'],
    width: '100%',
    '.text-line-skeleton-wrapper': {
      marginBottom: [4, '25px'],
    },
    '.form-title': theme.auth.formTitleStyles,
    '.form-text': theme.auth.formTextStyles,
  })
);
