import React from 'react';
import { FdrImage, FdrLink } from '@hotelplan/supergraph-api';
import {
  IFdrAutoSuggestItem,
  TNormalizedSearchItem,
  TSearchItem,
} from './fdr-search-overlay.types';

enum keys {
  DOWN = 'ArrowDown',
  UP = 'ArrowUp',
  ENTER = 'Enter',
}

interface IAutoSuggestKeyPressOptions {
  downHandler?: () => void;
  upHandler?: () => void;
  enterHandler?: () => void;
}

export function useFdrSearchAutoSuggestKeyPress({
  downHandler,
  enterHandler,
  upHandler,
}: IAutoSuggestKeyPressOptions): void {
  function handleKeyPress({ key }) {
    if (key === keys.DOWN) downHandler();
    if (key === keys.UP) upHandler();
    if (key === keys.ENTER) enterHandler();
  }

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  });
}

export function getNextFdrAutoSuggestItem(
  active: IFdrAutoSuggestItem,
  list: Array<IFdrAutoSuggestItem>
): IFdrAutoSuggestItem {
  if (!active) {
    return list.length ? list[0] : null;
  }

  if (list[active.index + 1]) {
    return list[active.index + 1];
  }

  if (list.length) {
    return list[0];
  }

  return null;
}

export function getPrevFdrAutoSuggestItem(
  active: IFdrAutoSuggestItem,
  list: Array<IFdrAutoSuggestItem>
): IFdrAutoSuggestItem {
  if (!active) {
    return list.length ? list[list.length - 1] : null;
  }

  if (list[active.index - 1]) {
    return list[active.index - 1];
  }

  if (list.length) {
    return list[list.length - 1];
  }

  return null;
}

/** normalizes text input and removes diacritics **/
const normalize = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/gu, '')
    .toLowerCase();
};

export function getSearchTermPosition(
  text: string,
  term: string
): [number, number] | null {
  const normalizedText = normalize(text);
  const normalizedTerm = normalize(term);
  const startIndex = normalizedText.indexOf(normalizedTerm);

  if (startIndex > -1) {
    return [
      startIndex,
      normalizedText.indexOf(normalizedTerm) + normalizedTerm.length - 1,
    ];
  }

  return null;
}

export function mapSearchItemToNormalizedSearchItem(
  searchItem: TSearchItem
): TNormalizedSearchItem {
  if (!searchItem) return null;

  function getSearchItem(item: {
    name?: string;
    webMeta?: { descriptionWebMeta?: { link: FdrLink }; link?: FdrLink };
    images?: Array<FdrImage>;
    hero?: FdrImage;
  }) {
    if (!item) return null;

    const { webMeta } = item;

    function hasDescriptionWebMeta(
      meta: typeof webMeta
    ): meta is { descriptionWebMeta: { link: FdrLink } } {
      return 'descriptionWebMeta' in meta;
    }

    return {
      name: item.name,
      link: hasDescriptionWebMeta(webMeta)
        ? webMeta.descriptionWebMeta.link
        : webMeta.link,
      image: item.images?.[0] || item.hero,
    };
  }

  switch (searchItem.__typename) {
    case 'FdrAdventureTravelSearchItem':
      return getSearchItem(searchItem.adventureTravel);
    case 'FdrHotelSearchItem':
      return getSearchItem(searchItem.hotel);
    case 'FdrRoundTripSearchItem':
      return getSearchItem(searchItem.roundTrip);
    case 'FdrThemeSearchItem':
      return getSearchItem(searchItem.theme);
    case 'FdrContinentSearchItem':
      return getSearchItem(searchItem.continent);
    case 'FdrCountryGroupSearchItem':
      return getSearchItem(searchItem.countryGroup);
    case 'FdrRegionSearchItem':
      return getSearchItem(searchItem.region);
    case 'FdrCountrySearchItem':
      return getSearchItem(searchItem.country);
    case 'FdrBlogArticleSearchItem':
      return getSearchItem(searchItem.blogArticle);
    case 'FdrCatalogPageSearchItem':
      return getSearchItem(searchItem.catalogPage);
    case 'FdrAgencyOverviewPageSearchItem':
      return getSearchItem(searchItem.agencyOverviewPage);
    case 'FdrNewsletterSubscriptionPageSearchItem':
      return getSearchItem(searchItem.newsletterSubscriptionPage);
  }
}
