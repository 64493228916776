import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';
import { FdrLinkType } from '@hotelplan/supergraph-api';
import { targetPageData } from 'components/util/target-page-data';

const useRedirectToBookmarkPage = () => {
  const [, { language }] = useTranslation();
  const { push } = useRouter();
  const [loading, setLoading] = useState(false);

  const redirect = useCallback(() => {
    const route = getRouteByPageTypeLocale(EPageType.Bookmark, language);

    if (!route || loading) return;

    setLoading(true);

    const url = route.builder({});

    const routerProps = targetPageData({
      __typename: 'FdrLink',
      type: FdrLinkType.Internal,
      url,
    });

    push(route.page, url, {
      shallow: false,
      scroll: true,
      locale: routerProps.locale,
    }).then(() => {
      setLoading(false);
    });
  }, [language]);

  return {
    redirect,
    loading,
  };
};

export default useRedirectToBookmarkPage;
