import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useState } from 'react';
import * as Yup from 'yup';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';
import {
  FdrFailureLoginErrorMessage,
  FdrLoginForm,
} from './fdr-b2b-login.styles';

type TFdrB2BLoginFormState = {
  username: string;
  password: string;
  userCode?: string;
};

const FdrB2BLoginValidationSchema = Yup.object<TFdrB2BLoginFormState>({
  username: Yup.string().required('forms:errors.agentCode.required'),
  password: Yup.string().required('forms:errors.password.required'),
  userCode: Yup.string().notRequired(),
});

interface IFdrB2bLoginFormProps {
  hasForgotPasswordLink?: boolean;
  onLogin?(state: Omit<TFdrB2BLoginFormState, 'password'>): void;
  onForgotPasswordClick?(): void;
}

export function FdrB2bLoginForm({
  hasForgotPasswordLink = true,
  onLogin,
  onForgotPasswordClick,
}: IFdrB2bLoginFormProps): ReactElement {
  const [t] = useTranslation(['forms', 'auth']);
  const { client } = useAuthContext();

  const [error, setError] = useState<boolean>(false);

  async function handleSubmit(
    values: TFdrB2BLoginFormState,
    helpers: FormikHelpers<TFdrB2BLoginFormState>
  ) {
    try {
      const { error: isError } = await client.b2bLogin(values);

      if (!isError) {
        onLogin(values);
        window.location.href = '/';
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      helpers.resetForm();
    }
  }

  return (
    <Formik<TFdrB2BLoginFormState>
      initialValues={{
        username: '',
        password: '',
        userCode: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={FdrB2BLoginValidationSchema}
    >
      {formik => {
        return (
          <>
            {error && (
              <FdrFailureLoginErrorMessage>
                {t('auth:login.failure')}
              </FdrFailureLoginErrorMessage>
            )}
            <FdrLoginForm
              noValidate
              onSubmit={(e: unknown) =>
                formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
              }
            >
              <ValidatedTextInput
                required
                name="username"
                label={t('forms:agentCode.label')}
                className="text-input"
                showErrorMessage
              />
              <ValidatedTextInput
                required
                name="password"
                type="password"
                autoComplete="current-password"
                label={t('forms:password.label')}
                className="text-input"
                showErrorMessage
              />
              <ValidatedTextInput
                name="userCode"
                label={t('forms:userCode.label')}
                className="text-input"
              />
              <BsButton
                disabled={formik.isSubmitting}
                className="login-form-submit"
                type="submit"
              >
                {t('forms:login.button')}
              </BsButton>
              {hasForgotPasswordLink && (
                <BsButton
                  variant="linkBtn"
                  className="login-form-forgot-button"
                  onClick={onForgotPasswordClick}
                >
                  {t('forms:forgotPassword')}
                </BsButton>
              )}
            </FdrLoginForm>
          </>
        );
      }}
    </Formik>
  );
}
