import { QueryResult } from '@apollo/client';
import { FdrSearchType } from '@hotelplan/supergraph-api';
import {
  FdrTextSearchAutocompleteQuery,
  useFdrTextSearchAutocompleteQuery,
} from 'schemas/query/search/fdr-text-search-autocomplete.generated';
import { TTextSearchType } from './fdr-search-overlay.types';

const ProductSearchTypes = [
  FdrSearchType.Adventuretravel,
  FdrSearchType.Hotel,
  FdrSearchType.ExtraHotel,
  FdrSearchType.Roundtrip,
];

const NonProductSearchTypes = [
  FdrSearchType.Continent,
  FdrSearchType.Countrygroup,
  FdrSearchType.Country,
  FdrSearchType.Region,
  FdrSearchType.Theme,
  FdrSearchType.BlogArticle,
  FdrSearchType.CataloguePage,
  FdrSearchType.AgencyOverviewPage,
  FdrSearchType.NewsletteRregistrationPage,
];

interface IFdrTextSearchAutoCompleteProps {
  searchValue: string;
  searchType: TTextSearchType;
  onCompleted?: (data: FdrTextSearchAutocompleteQuery) => void;
}

export function useFdrTextSearchAutoComplete({
  searchValue,
  searchType,
  onCompleted,
}: IFdrTextSearchAutoCompleteProps): QueryResult<FdrTextSearchAutocompleteQuery> {
  return useFdrTextSearchAutocompleteQuery({
    variables: {
      page: 0,
      userInput: searchValue,
      searchType:
        searchType === 'product' ? ProductSearchTypes : NonProductSearchTypes,
    },
    onCompleted: onCompleted,
  });
}
