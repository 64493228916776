import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ReactElement, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { ValidatedTextInput } from '@hotelplan/components.common.text-input';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { SSR_B2C_CROSS_LOGIN_ENDPOINT_PATH } from '@hotelplan/fdr.lib.ident.auth-config';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { getCallbackUrlQueryParamFromQuery } from '@hotelplan/libs.cross-login';
import { getRedirectQueryParamFromQuery } from '@hotelplan/libs.login-redirect';
import {
  getRouteByLangId,
  getRouteByPageTypeLocale,
} from '@hotelplan/libs.router-config';
import {
  FdrB2CLoginFormWrapper,
  FdrFailureLoginErrorMessage,
} from './fdr-b2c-login.styles';

interface IFdrB2CLoginFormState {
  username: string;
  password: string;
}

const FdrB2CLoginValidationSchema = Yup.object<IFdrB2CLoginFormState>({
  username: Yup.string()
    .required('forms:errors.email.required')
    .matches(
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
      'forms:errors.email.format'
    ),
  password: Yup.string().required('forms:errors.password.required'),
});

interface IFdrB2CLoginFormProps {
  onLogin?(): void;
  redirectPageType?: EPageType;
}

// TODO: Not sure do we need it as we now have mein-konto login service (remove fdr-auth-forms/b2c-login ?)
export function FdrB2CLoginForm({
  onLogin,
  redirectPageType = EPageType.MyaccountMybookings,
}: IFdrB2CLoginFormProps): ReactElement {
  const { query } = useRouter();
  const [t, { language }] = useTranslation(['forms', 'myaccount']);
  const [error, setError] = useState<boolean>(false);
  const { client } = useAuthContext();

  const redirectRoute = useMemo(
    () => getRouteByPageTypeLocale(redirectPageType, language),
    [language, redirectPageType]
  );

  async function handleSubmit(
    values: IFdrB2CLoginFormState,
    helpers: FormikHelpers<IFdrB2CLoginFormState>
  ) {
    try {
      const { error: isError } = await client.login(values);

      if (!isError) {
        onLogin();

        let redirectionHref = redirectRoute.builder({});

        const redirectUrlQueryParam = getRedirectQueryParamFromQuery(query);
        if (redirectUrlQueryParam) {
          const internalRedirectRoute = getRouteByLangId(
            redirectUrlQueryParam.value,
            language
          );
          if (internalRedirectRoute) {
            redirectionHref = internalRedirectRoute.builder({});
          }
        }

        const callbackUrlQueryParam = getCallbackUrlQueryParamFromQuery(query);

        if (callbackUrlQueryParam) {
          redirectionHref = `${SSR_B2C_CROSS_LOGIN_ENDPOINT_PATH}?${callbackUrlQueryParam.name}=${callbackUrlQueryParam.value}`;
        }

        window.location.href = redirectionHref;
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      helpers.resetForm();
    }
  }

  return (
    <Formik<IFdrB2CLoginFormState>
      initialValues={{
        username: '',
        password: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={FdrB2CLoginValidationSchema}
    >
      {formik => {
        return (
          <>
            {error && (
              <FdrFailureLoginErrorMessage>
                {t('myaccount:login.error')}
              </FdrFailureLoginErrorMessage>
            )}
            <FdrB2CLoginFormWrapper
              noValidate
              onSubmit={(e: unknown) =>
                formik.handleSubmit(e as React.FormEvent<HTMLFormElement>)
              }
            >
              <div className="text-input">
                <ValidatedTextInput
                  required
                  name="username"
                  label={t('myaccount:email.label')}
                  type="email"
                  autoComplete="email"
                  autoCorrect="off"
                  autoCapitalize="none"
                  showErrorMessage
                />
              </div>
              <div className="text-input">
                <ValidatedTextInput
                  required
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  label={t('forms:password.label')}
                  showErrorMessage
                />
              </div>
              <BsButton
                disabled={formik.isSubmitting}
                type="submit"
                className="login-form-submit"
              >
                {t('myaccount:login.button')}
              </BsButton>
            </FdrB2CLoginFormWrapper>
          </>
        );
      }}
    </Formik>
  );
}
