import { useRouter } from 'next/router';
import { useState } from 'react';
import { targetPageData } from 'components/util/target-page-data';
import { useFdrSrlLinkLazyQuery } from 'schemas/query/srl/fdr-srl-link.generated';

export function useFdrTextSearchLinkWithSection(): (
  query: string,
  sectionKeys: Array<string>
) => Promise<void> {
  const [sectionKeys, setSectionKeys] = useState<Array<string>>([]);
  const router = useRouter();

  const [requestLink] = useFdrSrlLinkLazyQuery({
    onCompleted(searchLinkData) {
      const { as, href } = targetPageData(
        searchLinkData?.fdrProductSearchResultListPage.webMeta.link
      );
      const hashString = sectionKeys?.length ? `#${sectionKeys.join(',')}` : '';

      router.push(`${href}${hashString}`, `${as}${hashString}`, {
        shallow: true,
      });
    },
  });

  return async function (query, keys) {
    setSectionKeys(keys);
    requestLink({
      variables: { searchString: query },
    });
  };
}
