export const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

export const mapOptions: google.maps.MapOptions = {
  streetViewControl: false,
  scaleControl: false,
  fullscreenControl: false,
  gestureHandling: 'greedy' as const,
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: 3,
  },
};

export enum FdrMapControlPosition {
  BOTTOM_CENTER = 'BOTTOM_CENTER',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  LEFT_BOTTOM = 'LEFT_BOTTOM',
  LEFT_CENTER = 'LEFT_CENTER',
  LEFT_TOP = 'LEFT_TOP',
  RIGHT_BOTTOM = 'RIGHT_BOTTOM',
  RIGHT_CENTER = 'RIGHT_CENTER',
  RIGHT_TOP = 'RIGHT_TOP',
  TOP_CENTER = 'TOP_CENTER',
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
}
