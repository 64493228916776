import styled from 'styled-components';
import {
  NotificationModal,
  WhiteBoxModal,
} from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { BsButton } from '@hotelplan/core.basis.bs-button';

export const FdrB2bForgotPasswordModal = styled(WhiteBoxModal).attrs({
  name: 'b2bForgotPasswordFormOverlay',
})(({ theme }) => theme.modals.forgotPasswordModal);

export const FdrB2bForgotPasswordNotificationModal = styled(NotificationModal)(
  ({ theme }) => ({
    '.modal-wrapper': theme.modals.smallModalWrapper,
  })
);

export const FdrForgotPasswordFormWrapper = styled.form(
  sx2CssThemeFn({
    '.form-text': {
      mb: [3, 4],
    },
    '.form-submit-button': {
      width: '100%',
      mb: [3, '20px'],
    },
  })
);

export const FdrForgotPasswordLinkWrapper = styled(BsButton)(
  ({ theme }) => theme.buttons.linkBtn
);
