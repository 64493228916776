import { getFdrOpenMethod } from '@hotelplan/fdr.lib.router.open-method';
import {
  getFdrRouterLink,
  getFdrTargetPageData,
} from '@hotelplan/fdr.lib.router.target-page';
import { FdrLink, FdrLinkType } from '@hotelplan/supergraph-api';

export function targetPageData(
  link: FdrLink,
  hiddenQueryParams: Record<string, string | undefined> = {}
) {
  if (!link) return null;
  if (link.__typename === 'FdrLink' && link.type === FdrLinkType.Internal)
    return getFdrTargetPageData(link.url, hiddenQueryParams);

  return null;
}

export function routerLink(link: FdrLink) {
  if (!link) return null;
  if (link.__typename === 'FdrLink') return getFdrRouterLink(link);

  return null;
}

export function openMethod(link: Partial<FdrLink>) {
  if (!link) return null;
  if (link.__typename === 'FdrLink') return getFdrOpenMethod(link.openMethod);

  return null;
}
