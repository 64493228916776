import styled from 'styled-components';
import { FdrBoosters } from 'components/candidate/fdr-boosters';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrBoostersWrapper = styled(FdrBoosters)(
  sx2CssThemeFn({
    bottom: ['76px', 3],
    '.booster-wrapper': {
      bg: 'primary',
      borderRadius: '0',
      mb: 0,
      '.booster-text': {
        p: [`16px 22px 16px 12px`, `16px 100px 16px 120px`],
        lineHeight: '1.2',
      },
      '.booster-text p': {
        fontSize: [2, '22px'],
      },
      '.booster-close-btn': {
        mt: [2, 3],
        mb: [2, 3],
        '.icon': {
          width: '20px',
          height: '20px',
        },
      },
    },
  })
);
