import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const FdrLoginSocialWrapper = styled.div(
  ({ theme }) => theme.auth.loginSocialWrapper
);

export const FdrFacebookLoginButton = styled(BsButton)(({ theme }) =>
  sx2CssThemeFn({
    ...theme.auth.loginSocialButton,
    backgroundColor: 'facebookButtonBackground',
    '.icon': {
      backgroundColor: 'facebookIconBackground',
    },
  })
);

export const FdrGoogleLoginButton = styled(BsButton)(({ theme }) =>
  sx2CssThemeFn({
    ...theme.auth.loginSocialButton,
    backgroundColor: 'googleButtonBackground',
    '.icon': {
      backgroundColor: 'googleIconBackground',
    },
  })
);
