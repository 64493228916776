import React from 'react';
import { FdrMapLoaderWrapper } from './fdr-map.styles';

interface IFdrMapLoaderProps {
  className?: string;
}

export function FdrMapLoader({
  className,
}: IFdrMapLoaderProps): React.ReactElement {
  return (
    <FdrMapLoaderWrapper
      data-id="map-loader"
      className={`map-loader-wrapper ${className}`}
    >
      <div className="map-loader" />
    </FdrMapLoaderWrapper>
  );
}
