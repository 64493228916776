import { FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactElement, useState } from 'react';
import * as Yup from 'yup';
import { NotificationType } from '@hotelplan/components.common.modals';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';
import { getCallbackUrlQueryParamFromQuery } from '@hotelplan/libs.cross-login';
import { FdrAuthFormOverlay } from 'components/candidate/fdr-auth-form-overlay';
import {
  FdrB2bForgotPasswordModal,
  FdrB2bForgotPasswordNotificationModal,
} from './fdr-b2b-forgot-password.styles';
import {
  FdrForgotPasswordForm,
  IFdrForgotPasswordFormState,
} from './fdr-forgot-password-form';

enum RequestStatusType {
  SUCCESS = 'success',
  ERROR = 'error',
}

const FdrForgotPasswordValidationSchema =
  Yup.object<IFdrForgotPasswordFormState>({
    username: Yup.string()
      .required('forms:errors.email.required')
      .matches(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
        'forms:errors.email.format'
      ),
  });

function getResetPasswordResultNotification(status: RequestStatusType): {
  type: NotificationType;
  text: string;
  title: string;
} {
  return {
    [RequestStatusType.SUCCESS]: {
      type: NotificationType.SUCCESS,
      title: `myaccount:forgotPassword.success.title`,
      text: `myaccount:forgotPassword.success.text`,
    },
    [RequestStatusType.ERROR]: {
      type: NotificationType.ERROR,
      title: `myaccount:forgotPassword.failure.title`,
      text: `myaccount:somethingWrongAgain`,
    },
  }[status];
}

interface IFdrB2BResetPasswordProps {
  isShown: boolean;
  onClose(): void;
}

export function FdrB2BResetPassword({
  isShown,
  onClose,
}: IFdrB2BResetPasswordProps): ReactElement {
  const [t, { language }] = useTranslation(['auth']);
  const router = useRouter();
  const { client } = useAuthContext();
  const [resetPasswordStatus, setResetPasswordStatus] =
    useState<RequestStatusType | undefined>(undefined);

  const resetPasswordRequestNotificationData = resetPasswordStatus
    ? getResetPasswordResultNotification(resetPasswordStatus)
    : null;

  async function handleFormSubmit(
    values: IFdrForgotPasswordFormState,
    helpers: FormikHelpers<IFdrForgotPasswordFormState>
  ) {
    try {
      const callbackUrlQueryParam = getCallbackUrlQueryParamFromQuery(
        router?.query
      );

      const { error } = await client.resetPassword({
        username: values.username,
        language,
        ...(callbackUrlQueryParam && {
          [callbackUrlQueryParam.name]: callbackUrlQueryParam.value,
        }),
      });

      setResetPasswordStatus(
        error ? RequestStatusType.ERROR : RequestStatusType.SUCCESS
      );

      onClose();
    } catch (e) {
      setResetPasswordStatus(RequestStatusType.ERROR);
    } finally {
      helpers.resetForm();
    }
  }

  return (
    <>
      <FdrB2bForgotPasswordModal
        title={t('myaccount:forgotPassword.title')}
        show={isShown}
        onClose={onClose}
      >
        <FdrAuthFormOverlay text={t('myaccount:forgotPassword.text')}>
          <FdrForgotPasswordForm
            usernameLabel={t('myaccount:forgotPassword.username')}
            onSubmit={handleFormSubmit}
            validationSchema={FdrForgotPasswordValidationSchema}
          />
        </FdrAuthFormOverlay>
      </FdrB2bForgotPasswordModal>
      {resetPasswordRequestNotificationData ? (
        <FdrB2bForgotPasswordNotificationModal
          show={!!resetPasswordStatus}
          name="forgotPasswordRequestResultModal"
          type={resetPasswordRequestNotificationData.type}
          title={t(resetPasswordRequestNotificationData.title)}
          onClose={() => setResetPasswordStatus(undefined)}
        >
          {t(resetPasswordRequestNotificationData.text)}
        </FdrB2bForgotPasswordNotificationModal>
      ) : null}
    </>
  );
}
