import { useGoogleMap } from '@react-google-maps/api';
import React, { useContext } from 'react';
import {
  FdrCustomMapControl,
  FdrMapControlPosition,
} from 'components/candidate/fdr-map';
import { fitBounds } from 'components/domain/fdr-geo/pins/fdr-geo-trips-pins.utils';
import { createResetMapControl } from 'components/domain/fdr-map';
import { FdrGeoTripsContext } from './fdr-geo-trips.context';
import { MAP_MAX_ZOOM } from './useMapCoordinates';

type TProps = { onClick: () => void };

const FdrResetMapButton: React.FC<TProps> = ({ onClick }: TProps) => {
  const map = useGoogleMap();
  const { routePoints, productPins } = useContext(FdrGeoTripsContext);

  const handleReset = () => {
    onClick?.();
    requestAnimationFrame(() => {
      fitBounds(
        map,
        [
          ...Array.from(routePoints.values()),
          ...Array.from(productPins.values()),
        ].map(({ coordinates }) => coordinates),
        MAP_MAX_ZOOM
      );
    });
  };
  return (
    <FdrCustomMapControl
      onCreateControl={createResetMapControl({ onClick: handleReset })}
      position={FdrMapControlPosition.RIGHT_BOTTOM}
    />
  );
};

export default FdrResetMapButton;
