import { PropsWithChildren, ReactElement } from 'react';
import { FdrAuthFormOverlayContent } from './fdr-auth-form-overlay.styles';

interface IFdrAuthFormOverlayProps {
  title?: string;
  text?: string;
}

export function FdrAuthFormOverlay({
  title,
  text,
  children,
}: PropsWithChildren<IFdrAuthFormOverlayProps>): ReactElement {
  return (
    <FdrAuthFormOverlayContent>
      {title ? (
        <h2 className="auth-form-title" data-id="auth-form-title">
          {title}
        </h2>
      ) : null}
      {text && (
        <div
          className="auth-form-text"
          data-id="auth-form-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {children}
    </FdrAuthFormOverlayContent>
  );
}
