import Cookies from 'js-cookie';
import { useTranslation } from 'next-i18next';
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  BOOSTERS_CLOSED_COOKIE_NAME,
  getBrowserMetaModulesContext,
  setBrowserMetaModulesContext,
  useMetaModulesContext,
} from '@hotelplan/components.common.meta-modules';
import { optionsWithPassive } from '@hotelplan/libs.polyfills';
import { FdrBooster } from '@hotelplan/supergraph-api';
import {
  FdrBoosterCloseButton,
  FdrBoostersList,
  FdrBoosterWrapper,
} from './fdr-boosters.styles';

const DEFAULT_TIMES_TO_REPEAT = 2;
const MS_IN_SECOND = 1000;
const CLOSED_BOOSTERS_FLAG_VALUE = 'true';

function areBoostersClosedOnce(): boolean {
  return !!Cookies.get(BOOSTERS_CLOSED_COOKIE_NAME);
}

interface IFdrBoostersProps {
  boosters: Array<FdrBooster>;
  timesToRepeat?: number;
  className?: string;
}

export function FdrBoosters({
  boosters,
  timesToRepeat = DEFAULT_TIMES_TO_REPEAT,
  className,
}: IFdrBoostersProps): ReactElement {
  const [t] = useTranslation('common');
  const { hideBoosters } = useMetaModulesContext();
  const [boostersOnceClosed, setBoostersOnceClosed] = useState(hideBoosters);

  // NOTE: We need to repeat boosters TIMES_TO_REPEAT times.
  const boostersToShow = useMemo(() => {
    return [].concat(
      ...Array(timesToRepeat).fill(boosters)
    ) as Array<FdrBooster>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boosters]);

  const [currentBoosterId, setCurrentBoosterId] = useState<string | undefined>(
    boostersOnceClosed ? undefined : boostersToShow[0]?.id
  );

  useEffect(() => {
    if (boostersOnceClosed) return;
    setCurrentBoosterId(boostersToShow[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boostersToShow]);

  const timerIDsRef = useRef<number[]>([]);

  const startTimersRef = useRef((boostersList: Array<FdrBooster>) => {
    let durationToWait = 0;
    let showBoosterIndefinitely = false;

    boostersList.map(({ duration }, index) => {
      // NOTE: If the zero duration was found or there is only one booster, then other boosters should not be shown
      // and the booster with 0 duration should stay indefinitely.
      if (!duration || boosters.length === 1) {
        showBoosterIndefinitely = true;
      }

      durationToWait += duration ? duration * MS_IN_SECOND : 0;

      if (!showBoosterIndefinitely) {
        const boosterTimer = setTimeout(
          (() => {
            if (areBoostersClosedOnce()) return;

            // NOTE: After timeout set the next booster.
            setCurrentBoosterId(
              boostersToShow[index + 1]
                ? boostersToShow[index + 1].id
                : undefined
            );
          }) as TimerHandler,
          durationToWait
        );

        timerIDsRef.current.push(boosterTimer);
      }
    });
  });

  useEffect(() => {
    if (!currentBoosterId || boostersOnceClosed) return;

    function onLoad() {
      startTimersRef.current(boostersToShow);
    }

    function clearTimeouts() {
      timerIDsRef.current.map(timerID => clearTimeout(timerID));
      timerIDsRef.current = [];
    }

    if (document.readyState === 'complete') {
      const requestId = window.requestAnimationFrame(onLoad);

      return function () {
        clearTimeouts();
        window.cancelAnimationFrame(requestId);
      };
    }

    window.addEventListener('load', onLoad, optionsWithPassive);

    return () => {
      clearTimeouts();
      window.removeEventListener('load', onLoad);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boosters]);

  function onCloseHandler() {
    Cookies.set(BOOSTERS_CLOSED_COOKIE_NAME, CLOSED_BOOSTERS_FLAG_VALUE);
    setBoostersOnceClosed(true);
    setBrowserMetaModulesContext({
      ...getBrowserMetaModulesContext(),
      hideBoosters: true,
    });
    setCurrentBoosterId(undefined);
  }

  if (boostersOnceClosed) return null;

  return (
    <FdrBoostersList data-id="fdr-booster-list" className={className}>
      {boosters?.map((booster, index) => {
        if (currentBoosterId !== booster.id) return;

        return (
          <FdrBoosterWrapper
            key={index}
            data-id={`fdr-booster-${booster.id}`}
            className="booster-wrapper"
          >
            <div
              className="booster-text"
              dangerouslySetInnerHTML={{ __html: booster.mainText }}
            />
            <FdrBoosterCloseButton
              variant="iconBtn"
              onClick={onCloseHandler}
              icon={{
                name: 'close',
              }}
            >
              {t('common:close')}
            </FdrBoosterCloseButton>
          </FdrBoosterWrapper>
        );
      })}
    </FdrBoostersList>
  );
}
