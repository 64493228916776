import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import {
  BsRsvGallery,
  OverlaySlideInfo,
  SlideInfo,
} from '@hotelplan/core.serving.bs-rsv-gallery';
import { FdrMedia } from '@hotelplan/supergraph-api';
import { mapFdrMediaToImageMediaItems } from 'components/domain/fdr-pdp-media-gallery';
import { renderMedia } from 'components/util/criterias';
import { FdrMediaGalleryContainer } from './fdr-media-gallery.styles';

interface IFdrResponsiveMediaGalleryProps {
  mediaItems: Array<FdrMedia>;
}

export const FdrResponsiveMediaGallery: FC<IFdrResponsiveMediaGalleryProps> = ({
  mediaItems,
}) => {
  const [t] = useTranslation('common');
  const mappedItems = mapFdrMediaToImageMediaItems(mediaItems);

  if (!mappedItems.length) return null;

  return (
    <FdrMediaGalleryContainer>
      <BsRsvGallery
        renderSlide={renderMedia}
        items={mappedItems}
        overlaySlideInfo={(item, slideIndex, count, isHideNavigation) => (
          <OverlaySlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
        slideInfo={(item, slideIndex, count, isHideNavigation) => (
          <SlideInfo
            item={item}
            slide={slideIndex + 1}
            count={count}
            copyrightLabel={t('common:copyright.photo')}
            isHideNavigation={isHideNavigation}
          />
        )}
      />
    </FdrMediaGalleryContainer>
  );
};
