import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';

export const FdrBoostersList = styled.div(
  sx2CssThemeFn({
    zIndex: 9,
    left: '50%',
    width: '100%',
    bottom: [3, 4],
    position: 'fixed',
    transform: 'translateX(-50%)',
    maxWidth: ['calc(100% - 48px)', '1170px'],
  })
);

export const FdrBoosterWrapper = styled.div(
  ({ theme }) => theme.boosters.boosterWrap
);

export const FdrBoosterCloseButton = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    mr: [null, 3],
    backgroundColor: 'transparent',
    mt: ['6px', '16px'],
    flexShrink: '0',
    '.icon': {
      variant: [null, 'icons.lg'],
    },
  })
);
