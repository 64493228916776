import { ParsedUrlQueryInput } from 'querystring';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { PropsWithChildren, ReactElement } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { getCallbackUrlQueryParamFromQuery } from '@hotelplan/libs.cross-login';
import { getRedirectQueryParamFromQuery } from '@hotelplan/libs.login-redirect';
import { getRouteByPageTypeLocale } from '@hotelplan/libs.router-config';

interface IFdrRegistrationRouteProps {
  query?: ParsedUrlQueryInput;
  className?: string;
}

// TODO: Not sure do we need it as we now have mein-konto login service (remove fdr-auth-routes ?)
export function FdrRegistrationRoute({
  query = {},
  className,
  children,
}: PropsWithChildren<IFdrRegistrationRouteProps>): ReactElement {
  const [, { language }] = useTranslation();
  const router = useRouter();
  const callbackUrlQueryParam = getCallbackUrlQueryParamFromQuery(
    router?.query
  );
  const route = getRouteByPageTypeLocale(
    EPageType.MyaccountRegistration,
    language
  );
  const pathname = route?.builder({});

  let linkQuery = callbackUrlQueryParam
    ? { [callbackUrlQueryParam.name]: callbackUrlQueryParam.value }
    : {};

  if (isEmpty(linkQuery)) {
    const redirectUrlQueryParam = getRedirectQueryParamFromQuery(router?.query);
    if (redirectUrlQueryParam) {
      linkQuery = { [redirectUrlQueryParam.name]: redirectUrlQueryParam.value };
    }
  }

  const resultQuery = {
    ...query,
    ...linkQuery,
  };

  console.log({ pathname, resultQuery });

  // TODO: Provide the correct props to FdrRoute
  return <FdrRoute className={className}>{children}</FdrRoute>;
}
