import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrCoordinates } from '@hotelplan/supergraph-api';

type IMapDefaultCenterContext = google.maps.LatLngLiteral | undefined;

const FdrMapDefaultCenterContext =
  React.createContext<IMapDefaultCenterContext>(undefined);

interface IFdrMapDefaultCenterProviderProps {
  coordinates?: FdrCoordinates;
}

export function FdrMapDefaultCenterProvider({
  coordinates,
  children,
}: PropsWithChildren<IFdrMapDefaultCenterProviderProps>): ReactElement {
  return (
    <FdrMapDefaultCenterContext.Provider
      value={
        coordinates
          ? { lat: coordinates.latitude, lng: coordinates.longitude }
          : undefined
      }
    >
      {children}
    </FdrMapDefaultCenterContext.Provider>
  );
}

export function useFdrMapDefaultCenter(): IMapDefaultCenterContext {
  return React.useContext(FdrMapDefaultCenterContext);
}
