import { PropsWithChildren, ReactElement } from 'react';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import {
  FdrLayoutBlock,
  FdrLayoutBlockWrapper,
  FdrLayoutWrapper,
} from './fdr-b2b-login.styles';

interface IFdrB2bLoginLayoutProps {
  mainTitle: string;
  mainText?: string;
  loading?: boolean;
}

export function FdrB2bLoginLayout({
  mainTitle,
  mainText,
  loading,
  children,
}: PropsWithChildren<IFdrB2bLoginLayoutProps>): ReactElement {
  return (
    <FdrLayoutWrapper>
      <FdrLayoutBlockWrapper>
        <FdrLayoutBlock>
          {loading ? (
            <div
              className="text-line-skeleton-wrapper"
              data-id="text-skeleton-wrap"
            >
              <FdrTextLineSkeleton width={250} />
            </div>
          ) : (
            <>
              <h2 className="form-title">{mainTitle}</h2>
              {mainText && (
                <div
                  className="form-text"
                  data-id="layout-text"
                  dangerouslySetInnerHTML={{
                    __html: mainText,
                  }}
                />
              )}
            </>
          )}
          {children}
        </FdrLayoutBlock>
      </FdrLayoutBlockWrapper>
    </FdrLayoutWrapper>
  );
}

interface IFdrTextLineSkeletonProps {
  width: number;
}

// TODO: add to bit.dev as a separate module ?
function FdrTextLineSkeleton({ width }: IFdrTextLineSkeletonProps) {
  const { mobile } = useDeviceType();

  return (
    <PlaceholderLoader
      uid={`TextLineSkeleton_${width}`}
      width={width}
      height={mobile ? 18 : 25}
      speed={2}
      style={{ width: `${width}px`, height: '18px' }}
    >
      <rect width={width} height={mobile ? 18 : 25} rx={8} />
    </PlaceholderLoader>
  );
}
