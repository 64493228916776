import { FormikHelpers } from 'formik';
import { useTranslation } from 'next-i18next';
import { ReactElement, useState } from 'react';
import * as Yup from 'yup';
import { NotificationType } from '@hotelplan/components.common.modals';
import { useAuthContext } from '@hotelplan/fdr.lib.ident.with-auth';
import { FdrAuthFormOverlay } from 'components/candidate/fdr-auth-form-overlay';
import {
  FdrB2bForgotPasswordModal,
  FdrB2bForgotPasswordNotificationModal,
} from './fdr-b2b-forgot-password.styles';
import {
  FdrForgotPasswordForm,
  IFdrForgotPasswordFormState,
} from './fdr-forgot-password-form';

enum RequestStatusType {
  SUCCESS = 'success',
  ERROR = 'error',
}

const FdrForgotPasswordValidationSchema =
  Yup.object<IFdrForgotPasswordFormState>({
    username: Yup.string().required('forms:errors.required'),
  });

function getForgotPasswordResultNotification(status: RequestStatusType): {
  type: NotificationType;
  text: string;
  title: string;
} {
  return {
    [RequestStatusType.SUCCESS]: {
      type: NotificationType.SUCCESS,
      title: `auth:b2b.forgotPassword.success.title`,
      text: `auth:b2b.forgotPassword.success.text`,
    },
    [RequestStatusType.ERROR]: {
      type: NotificationType.ERROR,
      title: `auth:b2b.forgotPassword.failure.title`,
      text: `auth:b2b.forgotPassword.failure.text`,
    },
  }[status];
}

interface IFdrB2bForgotPasswordProps {
  isShown: boolean;
  onClose: () => void;
  forgotPasswordLabel?: string;
}

export function FdrB2bForgotPassword({
  isShown,
  forgotPasswordLabel,
  onClose,
}: IFdrB2bForgotPasswordProps): ReactElement {
  const [t] = useTranslation(['auth']);
  const { client } = useAuthContext();
  const [forgotPasswordStatus, setForgotPasswordStatus] =
    useState<RequestStatusType | undefined>(undefined);

  const forgotPasswordRequestNotificationData = forgotPasswordStatus
    ? getForgotPasswordResultNotification(forgotPasswordStatus)
    : null;

  async function handleFormSubmit(
    values: IFdrForgotPasswordFormState,
    helpers: FormikHelpers<IFdrForgotPasswordFormState>
  ) {
    try {
      const { error } = await client.b2bForgotPassword(values.username);
      setForgotPasswordStatus(
        error ? RequestStatusType.ERROR : RequestStatusType.SUCCESS
      );
      onClose();
    } catch (e) {
      setForgotPasswordStatus(RequestStatusType.ERROR);
    } finally {
      helpers.resetForm();
    }
  }

  return (
    <>
      <FdrB2bForgotPasswordModal
        title={t('auth:b2b.forgotPassword.title')}
        show={isShown}
        onClose={onClose}
      >
        <FdrAuthFormOverlay text={t('auth:b2b.forgotPassword.text')}>
          <FdrForgotPasswordForm
            usernameLabel={forgotPasswordLabel}
            onSubmit={handleFormSubmit}
            validationSchema={FdrForgotPasswordValidationSchema}
          />
        </FdrAuthFormOverlay>
      </FdrB2bForgotPasswordModal>
      {forgotPasswordRequestNotificationData ? (
        <FdrB2bForgotPasswordNotificationModal
          show={!!forgotPasswordStatus}
          name="forgotPasswordRequestResultModal"
          type={forgotPasswordRequestNotificationData.type}
          title={t(forgotPasswordRequestNotificationData.title)}
          onClose={() => setForgotPasswordStatus(undefined)}
        >
          {t(forgotPasswordRequestNotificationData.text)}
        </FdrB2bForgotPasswordNotificationModal>
      ) : null}
    </>
  );
}
