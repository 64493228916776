import { useGoogleMap } from '@react-google-maps/api';
import { ReactElement, useEffect } from 'react';
import { FdrMapControlPosition } from './fdr-map.constants';

export type TCustomMapControl = {
  element: HTMLElement | undefined;
  cleanup?: () => void;
};

interface IFdrCustomMapControlProps {
  onCreateControl(): TCustomMapControl;
  position?: FdrMapControlPosition;
}

export function FdrCustomMapControl({
  onCreateControl,
  position = FdrMapControlPosition.TOP_RIGHT,
}: IFdrCustomMapControlProps): ReactElement {
  const map = useGoogleMap();

  useEffect(() => {
    if (!map) return;

    const controlPosition = google.maps.ControlPosition[position];

    const { element: mapControl, cleanup } = onCreateControl();
    let place = map.controls[controlPosition];
    place.push(mapControl);

    return () => {
      // remove control from map
      place = map.controls[controlPosition];
      if (!place) return;
      place.removeAt(place.getArray().indexOf(mapControl as any));

      // explicitly clean up event listeners
      cleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return null;
}
