import {
  GoogleMap,
  GoogleMapProps,
  LoadScriptNext,
  LoadScriptProps,
} from '@react-google-maps/api';
import { useTranslation } from 'next-i18next';
import React, { ReactElement, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useFdrMapDefaultCenter } from './fdr-map-default-center.context';
import { FdrMapLoader } from './fdr-map-loader';
import { googleMapKey, googleMapVersion } from './fdr-map.config';
import { defaultCenter, mapOptions } from './fdr-map.constants';
import { FdrMapWrapper } from './fdr-map.styles';

interface IFdrMapProviderProps
  extends Omit<GoogleMapProps, 'mapContainerStyle'> {
  mapRef?: React.RefObject<google.maps.Map>;
  styles?: React.CSSProperties;
  className?: string;
}

const FdrMapAbsoluteLoader = styled(FdrMapLoader)({
  position: 'absolute',
});

const MAP_LIBRARIES: LoadScriptProps['libraries'] = [`places`];

export function FdrMapProvider(props: IFdrMapProviderProps): ReactElement {
  const { mapRef, options, styles, onLoad, children, className, ...mapProps } =
    props;

  const [, { language }] = useTranslation();

  const providedDefaultCenter = useFdrMapDefaultCenter();
  const { center: centerProp = providedDefaultCenter || defaultCenter } = props;

  // Prevent changing center if lat & lng are not changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const center = useMemo(() => centerProp, [centerProp.lat, centerProp.lng]);

  const onLoadHandler = useCallback(
    map => {
      if (onLoad) {
        onLoad(map);
      }

      if (mapRef) {
        (mapRef as React.MutableRefObject<google.maps.Map>).current = map;
      }
    },
    [mapRef, onLoad]
  );

  return (
    <LoadScriptNext
      googleMapsApiKey={googleMapKey}
      version={googleMapVersion}
      loadingElement={<FdrMapLoader />}
      language={language}
      preventGoogleFontsLoading={true}
      libraries={MAP_LIBRARIES}
    >
      <FdrMapWrapper className={className}>
        <FdrMapAbsoluteLoader />
        <GoogleMap
          zoom={3}
          center={center}
          {...mapProps}
          options={{ ...mapOptions, ...options }}
          mapContainerStyle={{
            position: 'absolute',
            background: 'transparent',
            ...styles,
          }}
          onLoad={onLoadHandler}
        >
          {children}
        </GoogleMap>
      </FdrMapWrapper>
    </LoadScriptNext>
  );
}
