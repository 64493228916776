import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import {
  MediaImageFragmentDoc,
  MediaVideoFragmentDoc,
  MediaAudioFragmentDoc,
  MediaPdfFragmentDoc,
  MediaExternalFragmentDoc,
} from 'schemas/fragment/media/fdr-media.generated';
export type FdrMediaGalleryFragment = {
  __typename?: 'FdrMediaGallery';
  bgColor?: string | null;
  fgColor?: string | null;
  media: Array<
    | {
        __typename?: 'FdrAudio';
        title?: string | null;
        preview?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          mediaPreviewResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        resourceLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }
    | {
        __typename?: 'FdrExternalMedia';
        title?: string | null;
        preview?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          mediaPreviewResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        resourceLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }
    | {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        mediaImageResized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      }
    | {
        __typename?: 'FdrPdf';
        caption: string;
        preview?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          mediaPreviewResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }
    | {
        __typename?: 'FdrVideo';
        copyright?: string | null;
        width?: number | null;
        height?: number | null;
        preview?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          mediaPreviewResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        resourceLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      }
  >;
};

export const FdrMediaGalleryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrMediaGallery' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrMediaGallery' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'media' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaImage' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaVideo' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaAudio' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaPdf' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'mediaExternal' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MediaImageFragmentDoc.definitions,
    ...MediaVideoFragmentDoc.definitions,
    ...MediaAudioFragmentDoc.definitions,
    ...MediaPdfFragmentDoc.definitions,
    ...MediaExternalFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
