import styled from 'styled-components';

export const FdrMapLoaderWrapper = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  minWidth: '1px',
  minHeight: '1px',
  '.map-loader': {
    backgroundImage: 'url(/images/map-placeholder.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    filter: 'blur(25px)' as const,
    transform: 'scale(1.3)',
  },
});

export const FdrMapWrapper = styled.div({
  backgroundColor: '#aadaff',

  // missing styles due to `preventGoogleFontsLoading`
  '.gm-style-moc': {
    backgroundColor: 'rgba(0,0,0,.45)',
    pointerEvents: 'none',
    textAlign: 'center',
    transition: 'opacity ease-in-out',
  },
  '.gm-style-mot': {
    color: 'white',
    fontSize: '22px',
    margin: 0,
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    // improve text positioning
    padding: '0 10px',
  },
});
